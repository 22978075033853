<template>
  <page-content>
    <v-toolbar flat>
      <v-col cols="10" class="ma-0 pa-0">
      <app-tabs v-model="selectedTab">
        <template  v-for="(tab, i) of tabs">
          <v-tab :key="`tab-${i}`" :to="'#' + tab.id">{{ tab.name }}</v-tab>
        </template>
      </app-tabs>
      </v-col>
      <v-col cols="2" align="right">
        <create-btn icon="mdi-account-plus"  text="Utwórz" background="red" tooltip="Nowy użytkownik" @click="onNewUser"/>
      </v-col>
    </v-toolbar>
    <v-divider></v-divider>
    <app-toolbar>
      <v-col cols="9">
        <app-filter-field
          v-model="filter"
          @input="onFilter"
        ></app-filter-field>
      </v-col>
      <v-col cols="3">
        <app-sort-select
          v-model="sortBySelected"
          :items="sortBy"
          :item-text="(item) => item.name"
        ></app-sort-select>
      </v-col>
      <!--       <v-btn icon>
      <v-icon>mdi-magnify</v-icon>
    </v-btn>
    <v-btn icon>
      <v-icon>mdi-heart</v-icon>
    </v-btn>
    <v-btn icon>
      <v-icon>mdi-dots-vertical</v-icon>
    </v-btn> -->
    </app-toolbar>
    <v-divider></v-divider>
    <v-card-subtitle>
      <v-row align="center" justify="center">
        <v-col cols="6"> Nazwa </v-col>
        <v-col cols="3" align="center"> Utworzony </v-col>
        <v-col cols="3" align="center"> Ostatnie logowanie </v-col>
      </v-row>
    </v-card-subtitle>
    <v-divider></v-divider>
    <v-card-text class="" v-for="user of users" :key="user.id">
      <v-row align="center">
        <v-col cols="6">
          <user-info :user="user"></user-info>
        </v-col>
        <v-col cols="3" align="center">
          {{ user.date_joined | dateMedium }}
        </v-col>
        <v-col cols="3" align="center">
          {{ user.last_login | dateMedium }}
        </v-col>
      </v-row>
      <!-- <v-col cols="12">{{user}}</v-col> -->
      <v-row>
        <v-col cols="12" class="">
          <v-divider></v-divider>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-pagination v-model="page" :length="pages" circle></v-pagination>
    </v-card-actions>
  </page-content>
</template>

<script>
import UserService from "@/services/user.service"
// import TasksIconBadge from "@/components/TasksIconBadge"
// import { AgGridVue } from "ag-grid-vue";
// import EventService from "@/services/event.service";
// import TaskService from "@/services/task.service";
// import { formatDistance, parseJSON } from "date-fns";
// import { defaultLocale } from '@/filters/date'
// import TaskIcon from '@/components/TaskIcon'

export default {
  name: "Users",
  data() {
    return {
      tabs: UserService.getMainConditions(),
      selectedTab: null,
      sortBy:  UserService.getMainOrdering(),
      sortBySelected: UserService.getMainOrdering()[0],
      filter: "",
      users: [],
      page: 1,
      pages: 2
    }
  },
  watch: {
    page() {
      this.refresh()
    },
    selectedTab() {
      this.refresh()
    },
    sortBySelected() {
      this.refresh()
    }
  },
  filters: {},
  components: {},
  methods: {
    onNewUser() {
      console.log("New user")
      this.$router.push({ name: "New User", params: { user: {} } })
    },
    onEditUser(user) {
      this.$router.push({ name: "User", params: { user: user, userId: user.id } })
    },
    onFilter() {
      this.refresh()
    },
    refresh() {
      const conditions = this.tabs.find(t => t.id == this.selectedTab)?.conditions
      const ordering = this.sortBySelected.ordering
      UserService.fetchUsers(this.filter, conditions, this.page, ordering).then(data => {
        this.pages = data.pages
        this.users = data.results
      })
    }
  },
  created() {},
  beforeMount() {
    this.refresh()
  },
  beforeDestroy() {}
}
</script>